.App {
  text-align: center;
}

body {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  font-family: "Inter";
}

a {
  text-decoration: none;
}

.main {
  width: 100%;
  height: 100%;
  margin: auto;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;

  font-style: normal;
  font-weight: 100;
  font-size: 35px;
  line-height: 42px;
  color: #000000;
}
.header > p {
  margin: 0;
}
.header-logo {
  width: 150px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.top-image-square {
  margin-top: 45px;
  width: 300px;
  height: 300px;
  border-radius: 5px;
}
.top-image-rectangle {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.chapter {
  margin-top: 50px;
  width: 400px;
}
@media only screen and (max-width: 480px) {
  .chapter {
    width: 300px;
  }
}

.chapter-name {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
}

.chapter-title {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
}
@media only screen and (max-width: 480px) {
  .chapter-title {
    font-size: 18px;
  }
}
.chapter-content-title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
@media only screen and (max-width: 480px) {
  .chapter-content-title {
    font-size: 14px;
  }
}

.chapter-description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-align: left
}
@media only screen and (max-width: 480px) {
  .chapter-description {
    font-size: 8px;
  }
}

.chapter-member-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}
.chapter-member {
  margin-top: 30px;
}
.chapter-member-image {
  max-width: 130px;
  height: 170px;
  border-radius: 5px;
  object-fit: cover;
}
@media only screen and (max-width: 480px) {
  .chapter-member-image {
    max-width: 110px;
    height: 150px;
  }
}
.chapter-member-text {
  text-align: center;
  margin: 0px;
}
.name-ja {
  margin-top: 10px;
  font-size: 18px;
}
.name-en {
  font-size: 12px;
}
.position {
  font-size: 14px;
  margin-top: 5px;
}

.chapter-award {
  min-height: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.chapter-award-image {
  margin: auto;
  border-radius: 5px;
  object-fit: contain;
}
.chapter-award-content {
  max-width: 250px;
}
@media only screen and (max-width: 480px) {
  .chapter-award-content {
    max-width: 150px;
  }
}
.chapter-award-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  text-align: left
}
@media only screen and (max-width: 480px) {
  .chapter-award-title {
    font-size: 13px;
  }
}
.chapter-award-description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-align: left
}
@media only screen and (max-width: 480px) {
  .chapter-award-description {
    font-size: 8px;
  }
}

.chapter-news {
  display: flex;
}
.chapter-news-date {
  max-width: 100px;
  margin-top: 16px;
  margin-right: 20px;
  padding-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.chapter-news-content {
  margin-top: 0px;
}
.chapter-news-title {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  text-align: left
}
@media only screen and (max-width: 480px) {
  .chapter-news-title {
    font-size: 13px;
  }
}
.chapter-news-description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-align: left
}
@media only screen and (max-width: 480px) {
  .chapter-news-description {
    font-size: 8px;
  }
}

.footer {
  width: 100%;
  margin-top: 54px;

  border-top: 0.5px solid #000000;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
